<template>
  <v-dialog
    v-model="dialog"
    width="500"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="rounded-lg"
        v-if="['shibui'].includes(currentUser.currentRoleName)"
        depressed
        color="success"
        @click=""
        v-bind="attrs"
        v-on="on"
        >
        Crear
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="justify-center">
        Crear dashboard
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="valid"
          lazy-validation
          ref="form"
          >
          <v-text-field
            outlined
            v-model="attributes.name"
            label="Nombre"
            :rules="[ v => !!v || 'Campo obligatorio']"
            ></v-text-field>
          <v-text-field
            outlined
            v-model="attributes.url"
            label="URL"
            :rules="[ v => !!v || 'Campo obligatorio']"
            ></v-text-field>
          <v-autocomplete
            outlined
            multiple
            :items="roles"
            item-text="name"
            item-value="value"
            v-model="attributes.allowedRoles"
            label="Usuarios autorizados"
            :rules="[ v => !!v.length || 'Campo obligatorio']"
            ></v-autocomplete>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="loading"
          :loading="loading"
          color="primary"
          text
          @click="createDashboard"
          >
          Crear
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { CreateDashboard } from '@/graphql/mutations/dashboards'
import { Roles } from '@/utils/roles'

export default {
  data: () => ({
    roles: Roles,
    dialog: false,
    valid: true,
    loading: false,
    attributes: {
      name: null,
      url: null,
      allowedRoles: []
    }
  }),

  computed: {
    ...mapGetters(['currentUser'])
  },

  methods: {
    createDashboard () {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$apollo.mutate({
          mutation: CreateDashboard,
          variables: {
            input: {
              ...this.attributes,
              id: this.$route.params.id
            }
          }
        }).then ( res => {
          this.$router.go()
        })
      }
    }
  }
}
</script>
